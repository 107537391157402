export const ConfigBuilder = ( appConfig: AppConfigService ) => {
    return () => {
        return appConfig.loadConfig();
    };
};

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { AppEffects, appReducer } from './state';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AccessDeniedComponent } from './dashboard/access-denied/access-denied.component';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { TokenService } from './core/services/token.service';
import { ToastrModule } from 'ngx-toastr';
import { AppConfigService } from './core/services/appConfigService';

@NgModule( {
	declarations: [
		AppComponent,
		AccessDeniedComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		RouterModule,
		ToastrModule.forRoot(),
		HighchartsChartModule,
		HttpClientModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot( {
			loader: {
				provide: TranslateLoader,
				useFactory: ( http: HttpClient ): TranslateHttpLoader => new TranslateHttpLoader( http, 'assets/i18n/', '.json' ),
				deps: [ HttpClient ]
			}
		} ),
		StoreModule.forRoot( { 'antar': appReducer }, {
			runtimeChecks: {
				strictStateImmutability: true,
				strictActionImmutability: true,
				strictStateSerializability: true,
				strictActionSerializability: true
			}
		} ),
		EffectsModule.forRoot( [ AppEffects ] ),
		StoreDevtoolsModule.instrument( {
			name: 'Antar DevTools' } )
	],
	providers: [
	{
		provide: APP_INITIALIZER,
		useFactory: ConfigBuilder,
		deps: [ AppConfigService, TokenService ],
		multi: true
	  } ],
	bootstrap: [ AppComponent ]
} )
export class AppModule { }


